import { Component } from '../../../libs/components';


class LightboxComponent extends Component {
    private overlayElement: HTMLElement | null = null;
    private contentElement: HTMLElement | null = null;
    private readonly lightboxId = 'lightbox-root';

    /**
     * Open the lightbox overlay with either a video or a photo.
     * If the lightbox already exists, its content is overwritten.
     * @param mediaType - The type of media to display ('video' or 'photo').
     * @param src - The source URL for the media.
     */
    public open(mediaType: 'video' | 'photo', src: string): void {
        // Check if the lightbox element already exists in the DOM
        const existingOverlay = document.getElementById(this.lightboxId);
        if (existingOverlay) {
            // Reuse existing overlay element
            this.overlayElement = existingOverlay;
            // Remove all existing children to overwrite the content
            while (this.overlayElement.firstChild) {
                this.overlayElement.removeChild(this.overlayElement.firstChild);
            }
        } else {
            // Create a new overlay element if it doesn't exist
            this.overlayElement = this.createOverlay();
            this.overlayElement.id = this.lightboxId;
            document.body.appendChild(this.overlayElement);
        }

        this.contentElement = this.createContentContainer();
        const closeButton = this.createCloseButton();
        this.overlayElement.appendChild(closeButton);

        let mediaElement: HTMLElement;
        let mediaSelector: string;

        if (mediaType === 'video') {
            mediaElement = this.createVideoElement(src);
            mediaSelector = 'video';
        } else {
            mediaElement = this.createPhotoElement(src);
            mediaSelector = 'img';
        }
        this.contentElement.appendChild(mediaElement);
        this.overlayElement.appendChild(this.contentElement);

        const renderedMedia = this.contentElement.querySelector(mediaSelector) as HTMLElement;
        renderedMedia.addEventListener('load', () => this.updateMediaSize(renderedMedia));

        // Add event listener to close overlay when clicking outside the content area
        this.overlayElement.addEventListener('click', (event: MouseEvent) => {
            if (event.target === this.overlayElement) {
                this.close();
            }
        });

        // Ability to close the overlay using the escape key
        function escapeHandlerForLightbox(event) {
            if (event.key === 'Escape' || event.keyCode === 27) {
                this.close();
                document.removeEventListener('keydown', escapeHandlerForLightbox);
            }
        }
        document.addEventListener('keydown', escapeHandlerForLightbox.bind(this));
    }

    /**
     * Close the lightbox overlay and remove all created HTML elements.
     */
    public close(): void {
        if (this.overlayElement && this.overlayElement.parentNode) {
            this.overlayElement.parentNode.removeChild(this.overlayElement);
        }
        // Reset elements
        this.overlayElement = null;
        this.contentElement = null;
    }

    /**
     * Create the overlay element with the necessary class.
     * @returns The overlay HTMLElement.
     */
    private createOverlay(): HTMLElement {
        const overlay = document.createElement('div');
        overlay.className = 'lightbox lightbox--overlay';
        return overlay;
    }

    /**
     * Create the content container element.
     * @returns The content container HTMLElement.
     */
    private createContentContainer(): HTMLElement {
        const content = document.createElement('div');
        content.className = 'lightbox__content';
        return content;
    }

    /**
     * Create the close button element.
     * @returns The close button HTMLElement.
     */
    private createCloseButton(): HTMLElement {
        const button = document.createElement('button');
        button.className = 'lightbox__close-button';
        // button.textContent = '×';
        button.addEventListener('click', () => {
            this.close();
        });
        return button;
    }

    /**
     * Create a video element for HTML5 video embedding.
     * @param src - The source URL of the video.
     * @returns The video HTMLElement.
     */
    private createVideoElement(src: string): HTMLElement {
        const video = document.createElement('video');
        video.className = 'lightbox__media lightbox__media--video';
        video.src = src;
        video.controls = true;
        video.autoplay = true;
        return video;
    }

    /**
     * Create an image element for displaying a photo.
     * @param src - The source URL of the photo.
     * @returns The image HTMLElement.
     */
    private createPhotoElement(src: string): HTMLElement {
        const image = document.createElement('img');
        image.className = 'lightbox__media lightbox__media--image';
        image.src = src;
        return image;
    }

    /**
     * Calculate the maximum width for the image.
     * @param media - The HTMLImageElement which needs being calculated
     * @returns The width to be set
     */
    private updateMediaSize(media: HTMLElement) {
        if (media.tagName.toLowerCase() == 'img') {
            const image = media as HTMLImageElement;

            if (image.naturalWidth > image.naturalHeight) {
                // It's a landscape image.
                const calculatedWidth = image.naturalWidth / image.naturalHeight * image.clientHeight;
                image.width = calculatedWidth;
            }
        }
    }

    onInit() {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            const mediaType = (event.currentTarget as HTMLElement).getAttribute('data-lightbox') as 'video' | 'photo';
            const mediaSource = (event.currentTarget as HTMLElement).getAttribute('href');
            this.open(mediaType, mediaSource);
        });
    }
}


LightboxComponent.declare('[data-lightbox]');
